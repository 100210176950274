
import axios from 'axios';

// Base URL for the Cloudflare Worker proxy
const WORKER_BASE_URL = 'https://tmdb.josephdennisjoo71.workers.dev';

export const fetchMovies = async (endpoint) => {
  try {
    const response = await axios.get(`${WORKER_BASE_URL}/movies${endpoint}`, {
      params: {
        language: 'en-US',
        include_adult: 'false'
      }
    });
    return response.data.results;
  } catch (error) {
    console.error(`Error fetching movies from ${endpoint}:`, error);
    throw error;
  }
};

export const fetchMovieDetails = async (id) => {
  const endpoint = `/movie/${id}`;
  try {
    const response = await axios.get(`${WORKER_BASE_URL}/movies${endpoint}`, {
      params: {
        language: 'en-US'
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching movie details for ${id}:`, error);
    throw error;
  }
};

export const fetchMovieVideos = async (id) => {
  const endpoint = `/movie/${id}/videos`;
  try {
    const response = await axios.get(`${WORKER_BASE_URL}/movies${endpoint}`, {
      params: {
        language: 'en-US'
      }
    });
    return response.data.results;
  } catch (error) {
    console.error(`Error fetching movie videos for ${id}:`, error);
    throw error;
  }
};

export const fetchSearchSuggestions = async (query) => {
  const endpoint = '/search/movie';
  try {
    const response = await axios.get(`${WORKER_BASE_URL}/movies${endpoint}`, {
      params: {
        query: query,
        language: 'en-US',
        page: 1,
        include_adult: false
      }
    });
    return response.data.results;
  } catch (error) {
    console.error(`Error fetching search suggestions for ${query}:`, error);
    throw error;
  }
};

export const fetchSimilarMovies = async (id) => {
  const endpoint = `/movie/${id}/similar`;
  try {
    const response = await axios.get(`${WORKER_BASE_URL}/movies${endpoint}`, {
      params: {
        language: 'en-US'
      }
    });
    return response.data.results;
  } catch (error) {
    console.error(`Error fetching similar movies for ${id}:`, error);
    throw error;
  }
};
