import React, { useEffect, useState } from 'react';
import SimpleMovieCard from '../components/movie_card_simple/simple_movie_card';
import { CustomHorizondalList } from '../components/horizondalList';
import { fetchMovies, fetchMovieDetails } from '../api/api';
import { LoadingMovie } from '../components/loaders/loading_movies'; // Import the loading component

const MOVIE_LIST_LINK = 'https://raw.githubusercontent.com/JoeTinnySpace/custom-movie-list-tmdb/main/editorspick.json';

const Lists = () => {
  const [popularMovies, setPopularMovies] = useState([]);
  const [myMoviesList, setMyMoviesList] = useState([]);
  const [error, setError] = useState(null);
  const [localStorageError, setLocalStorageError] = useState(null);
  const [movieLists, setMovieLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading to true at start of fetch
        const movieLists = await fetchMovieLists(MOVIE_LIST_LINK);
        setMovieLists(movieLists);
      } catch (err) {
        setError(`Failed to fetch movie lists: ${err.message}`);
      }

      const storedMovies = localStorage.getItem('myMoviesList');
      try {
        if (storedMovies && storedMovies.length > 2) {
          setMyMoviesList(JSON.parse(storedMovies));
        } else {
          setLocalStorageError('Your list is currently empty.');
        }
      } catch (err) {
        setLocalStorageError('Failed to load your list.');
      }

      try {
        const popularMovies = await fetchMovies('/movie/popular');
        setPopularMovies(popularMovies);
      } catch (err) {
        setError(`Failed to fetch popular movies: ${err.message}`);
      } finally {
        setIsLoading(false); // Set loading to false when all fetches complete
      }
    };
    fetchData();
  }, []);

  const removeFromMyMoviesList = (movieId) => {
    const updatedList = myMoviesList.filter((movie) => movie.id !== movieId);
    setMyMoviesList(updatedList);
    localStorage.setItem('myMoviesList', JSON.stringify(updatedList));
  };

  // If loading, show loading component
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingMovie message={'Loading movie lists...'} />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 md:p-8 rounded-3xl mb-12">
      <div className="space-y-10">
        {error && <div className="text-center text-red-500">{error}</div>}

        {/* My List Section */}
        <div>
          <h1 className="text-3xl font-semibold mb-4 border-b border-gray-700 pb-2">My List</h1>
          {localStorageError ? (
            <p className="text-center text-gray-400">{localStorageError}</p>
          ) : (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
              {myMoviesList.map((myMovie) => (
                <SimpleMovieCard
                  key={myMovie.id}
                  movie={myMovie}
                  onRemove={() => removeFromMyMoviesList(myMovie.id)}
                />
              ))}
            </div>
          )}
        </div>

        {/* Custom Lists Section */}
        {movieLists.map(({ listTitle, movies, error }) => (
          <CustomHorizondalList key={listTitle} listItems={movies} listTitle={listTitle} listError={error} />
        ))}

        <CustomHorizondalList listItems={popularMovies} listTitle='Popular Movies' listError='No popular movies available.' />
      </div>
    </div>
  );
};

export default Lists;

// Keep the existing fetchMovieLists function
async function fetchMovieLists(link) {
  try {
    const response = await fetch(link);
    const data = await response.json();
    const movieLists = [];
    for (const [listTitle, movieIds] of Object.entries(data)) {
      const movies = await Promise.all(
        movieIds.map(async (id) => await fetchMovieDetails(id))
      );
      movieLists.push({ listTitle, movies });
    }
    return movieLists;
  } catch (err) {
    console.error(`Failed to load movies from ${link}: ${err}`);
    return [{ listTitle: "Out of ideas! *_*", movies: [], error: "What's the title of your favorite movie you've watched recently?" }];
  }
}