import React, { useEffect } from 'react';

const AdSenseArticleAd = ({ publisherId, adUnitName }) => {
    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
            console.error('AdSense ad loading error:', error);
        }
    }, []);

    return (
        <div className="w-full px-4 sm:px-0 rounded-xl">
            <ins
                className="adsbygoogle"
                style={{
                    display: 'block',
                    width: '100%',
                    minWidth: '250px',
                    maxHeight: '300px',
                    overflow: 'hidden'
                }}
                data-ad-format="fluid"
                data-ad-client={publisherId}
                data-ad-slot={adUnitName}
                data-ad-layout-key="+2t+rl+2h-1m-4u"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default AdSenseArticleAd;