import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faEarth } from '@fortawesome/free-solid-svg-icons/faEarth';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons/faExternalLink';

function Navbar() {
  return (
    <nav className="fixed bottom-0 w-full bg-gray-900 text-white flex justify-around py-3 shadow-lg 
                    md:max-w-md md:mx-auto md:rounded-t-xl">
      <NavLink
        to="/"
        className={({ isActive }) => `flex flex-col items-center justify-center text-sm w-20 h-16 rounded-lg border 
          ${isActive ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-400 hover:text-blue-300 hover:border-blue-300'} 
          transition-colors duration-200`}
      >
        <FontAwesomeIcon icon={faEarth} className="text-xl mb-1" />
        <span>Discover</span>
      </NavLink>



      <NavLink
        to="/lists"
        className={({ isActive }) => `flex flex-col items-center justify-center text-sm w-20 h-16 rounded-lg border 
          ${isActive ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-400 hover:text-blue-300 hover:border-blue-300'} 
          transition-colors duration-200`}
      >
        <FontAwesomeIcon icon={faList} className="text-xl mb-1" />
        <span>Lists</span>
      </NavLink>

      {/* <NavLink
        to="/blog"
        className={({ isActive }) => `flex flex-col items-center justify-center text-sm w-20 h-16 rounded-lg border 
          ${isActive ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-400 hover:text-blue-300 hover:border-blue-300'} 
          transition-colors duration-200`}
      >
        <FontAwesomeIcon icon={faExternalLink} className="text-xl mb-1" />
        <span>Blog</span>
      </NavLink> */}

      <a
        href="https://blog.onemoviediscover.com"
        className="flex flex-col items-center justify-center text-sm w-20 h-16 rounded-lg hover:border 
    text-gray-400 hover:text-blue-300 hover:border-blue-300 transition-colors duration-200"
        target="_blank" // Optional: Opens in a new tab
        rel="noopener noreferrer" // For security when opening in a new tab
      >
        <FontAwesomeIcon icon={faExternalLink} className="text-xl mb-1" />
        <span>Blog</span>
      </a>

      <NavLink
        to="/about"
        className={({ isActive }) => `flex flex-col items-center justify-center text-sm w-20 h-16 rounded-lg border 
          ${isActive ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-400 hover:text-blue-300 hover:border-blue-300'} 
          transition-colors duration-200`}
      >
        <FontAwesomeIcon icon={faInfoCircle} className="text-xl mb-1" />
        <span>About</span>
      </NavLink>


    </nav>
  );
}

export default Navbar;