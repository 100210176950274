import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ 
  searchQuery, 
  onSearch, 
  searchSuggestions, 
  onSelectMovie, 
  onClearSearch,
  onAddToList
}) => {
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [addedMovies, setAddedMovies] = React.useState(new Set());
  
  const handleAddToList = (movie, event) => {
    event.stopPropagation();
    onAddToList(movie);
    setAddedMovies(prev => new Set(prev).add(movie.id));
    
    setTimeout(() => {
      setAddedMovies(prev => {
        const newSet = new Set(prev);
        newSet.delete(movie.id);
        return newSet;
      });
    }, 2000);
  };

  const handleMovieSelect = (movie) => {
    onSelectMovie(movie);
    setIsSearchFocused(false);
  };

  return (
    <div className="w-full md:max-w-md mx-auto px-4">
      <div className="relative">
        <div className="relative">
          <input
            type="text"
            placeholder="Search movies..."
            value={searchQuery}
            onChange={(e) => onSearch(e)}
            onFocus={() => setIsSearchFocused(true)}
            onBlur={() => setTimeout(() => {
              if (!document.activeElement?.closest('.search-container')) {
                setIsSearchFocused(false);
              }
            }, 200)}
            className="w-full h-12 pl-12 pr-10 rounded-full bg-gray-800/90 border border-gray-700 
                     text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-2 
                     focus:ring-blue-500 focus:border-transparent transition-all duration-200"
          />
          <FontAwesomeIcon 
            icon={faSearch} 
            className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"
          />
          {searchQuery && (
            <button
              onClick={() => {
                onClearSearch();
                setIsSearchFocused(false);
              }}
              className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 
                       hover:text-gray-200 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>

        {isSearchFocused && searchSuggestions.length > 0 && (
          <div className="absolute w-full mt-2 bg-gray-800/95 rounded-lg shadow-xl 
                          border border-gray-700 overflow-hidden z-50">
            <div className="max-h-96 overflow-y-auto">
              {searchSuggestions.map((movie) => (
                <div
                  key={movie.id}
                  onClick={() => handleMovieSelect(movie)}
                  className="flex items-center p-3 hover:bg-gray-700/50 cursor-pointer 
                           transition-colors duration-200 group"
                >
                  <div className="w-12 h-16 flex-shrink-0 bg-gray-700 rounded overflow-hidden">
                    {movie.poster_path ? (
                      <img
                        src={`https://image.tmdb.org/t/p/w92${movie.poster_path}`}
                        alt={movie.title}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center bg-gray-800 text-gray-600">
                        No Image
                      </div>
                    )}
                  </div>
                  <div className="ml-3 flex-1 min-w-0">
                    <h3 className="text-gray-100 font-medium truncate">
                      {movie.title}
                    </h3>
                    <p className="text-gray-400 text-sm">
                      {movie.release_date?.slice(0, 4) || 'N/A'}
                    </p>
                  </div>
                  <button
                    onClick={(e) => handleAddToList(movie, e)}
                    className={`ml-4 px-3 py-1 rounded-full text-sm font-medium 
                              transition-all duration-200 transform hover:scale-105
                              ${addedMovies.has(movie.id)
                                ? 'bg-green-500 text-white'
                                : 'bg-blue-600 text-white sm:opacity-0 sm:group-hover:opacity-100'
                              }`}
                  >
                    <FontAwesomeIcon 
                      icon={addedMovies.has(movie.id) ? faCheck : faPlus} 
                      className="mr-1"
                    />
                    <span className="hidden sm:inline">
                      {addedMovies.has(movie.id) ? 'Added' : 'Add to List'}
                    </span>
                    <span className="sm:hidden">
                      {addedMovies.has(movie.id) ? 'Added' : 'Add'}
                    </span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;