const ArrowKeys = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 mt-12">
      {/* Top Row (Up Arrow) */}
      <div className="flex gap-4">
        <div className="flex flex-col items-center text-center">
          <div className="w-12 h-12 bg-blue-400 rounded-md flex justify-center items-center text-white font-bold text-2xl relative">
            <span className="absolute">↑</span>
          </div>
          <span className="text-white text-sm mt-2">Skip</span>
        </div>
      </div>
      {/* Middle Row (Previous, Next, and Add to Collection) */}
      <div className="flex gap-4">

        <div className="flex flex-col items-center text-center">
          <div className="w-12 h-12 bg-blue-400 rounded-md flex justify-center items-center text-white font-bold text-2xl relative">
            <span className="absolute">←</span>
          </div>
          <span className="text-white text-sm mt-2">Previous</span>
        </div>
        
        <div className="flex flex-col items-center text-center">
          <div className="w-12 h-12 bg-blue-400 rounded-md flex justify-center items-center text-white font-bold text-2xl relative">
            <span className="absolute">↓</span>
          </div>
          <span className="text-white text-sm mt-2">My List</span>
        </div>

        <div className="flex flex-col items-center text-center">
          <div className="w-12 h-12 bg-blue-400 rounded-md flex justify-center items-center text-white font-bold text-2xl relative">
            <span className="absolute">→</span>
          </div>
          <span className="text-white text-sm mt-2">Next</span>
        </div>

        
      </div>
    </div>
  );
};


export default ArrowKeys;
