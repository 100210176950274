import React, { useRef, useState, useEffect } from 'react';
import SimpleMovieCard from '../components/movie_card_simple/simple_movie_card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export function CustomHorizondalList({ listItems, listTitle, listError }) {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const rafId = useRef(null);

  const checkScroll = () => {
    if (rafId.current) {
      cancelAnimationFrame(rafId.current);
    }
    
    rafId.current = requestAnimationFrame(() => {
      const container = scrollContainerRef.current;
      if (container) {
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(
          container.scrollLeft < container.scrollWidth - container.clientWidth - 1
        );
      }
    });
  };

  useEffect(() => {
    checkScroll();
    const debouncedResize = debounce(checkScroll, 150);
    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
    };
  }, [listItems]);

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -400 : 400;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const handleWheel = (e) => {
    if (scrollContainerRef.current && (e.shiftKey || Math.abs(e.deltaX) > Math.abs(e.deltaY))) {
      e.preventDefault();
      const scrollAmount = e.deltaY || e.deltaX;
      scrollContainerRef.current.scrollLeft += scrollAmount;
    }
  };

  // Optimized touch handling
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const x = e.touches[0].pageX;
    const walk = (startX - x) * 1.5; // Reduced multiplier for smoother scrolling
    
    if (rafId.current) {
      cancelAnimationFrame(rafId.current);
    }
    
    rafId.current = requestAnimationFrame(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft = scrollLeft + walk;
      }
    });
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (rafId.current) {
      cancelAnimationFrame(rafId.current);
    }
  };

  return (
    <div className="relative">
      <h1 className="text-2xl font-semibold mb-4 border-b border-gray-700 pb-2">
        {listTitle}
      </h1>
     
      {listItems.length > 0 ? (
        <div className="group relative">
          {/* Left scroll button */}
          {canScrollLeft && (
            <button
              onClick={() => scroll('left')}
              className="absolute -left-4 top-1/2 -translate-y-1/2 z-10 bg-black/70 hover:bg-black/90 
                       w-12 h-24 rounded-r-lg opacity-0 group-hover:opacity-100 
                       transition-opacity duration-300 flex items-center justify-center
                       focus:outline-none focus:ring-2 focus:ring-white/20"
            >
                <FontAwesomeIcon icon={faChevronLeft} className="text-xl text-white/90"/>
              {/* <i className="text-xl text-white/90"></i> */}
            </button>
          )}

          {/* Scrollable container */}
          <div
            ref={scrollContainerRef}
            onScroll={checkScroll}
            onWheel={handleWheel}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            className={`flex overflow-x-auto gap-4 pb-4 scrollbar-hide scroll-smooth select-none
                      ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
            style={{
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              WebkitOverflowScrolling: 'touch',
              transform: 'translateZ(0)', // Hardware acceleration
              willChange: 'scroll-position' // Hint to browser about animation
            }}
          >
            {listItems.map((listItem) => (
              <div 
                key={listItem.id} 
                className="flex-none w-[calc(50%-0.5rem)] md:w-[calc(33.333%-0.75rem)] lg:w-[calc(25%-0.75rem)]"
                style={{ transform: 'translateZ(0)' }} // Hardware acceleration for children
              >
                <SimpleMovieCard movie={listItem} />
              </div>
            ))}
          </div>

          {/* Right scroll button */}
          {canScrollRight && (
            <button
              onClick={() => scroll('right')}
              className="absolute -right-4 top-1/2 -translate-y-1/2 z-10 bg-black/70 hover:bg-black/90 
                       w-12 h-24 rounded-l-lg opacity-0 group-hover:opacity-100 
                       transition-opacity duration-300 flex items-center justify-center
                       focus:outline-none focus:ring-2 focus:ring-white/20"
            >
              <FontAwesomeIcon icon={faChevronRight} className="text-xl text-white/90"/>
            </button>
          )}
        </div>
      ) : (
        <p className="text-center text-gray-400">{listError}</p>
      )}
    </div>
  );
}